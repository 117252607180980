<!--
 * @Description: 项目根组件
 -->
<template>
  <div id="app" name="app">
    <el-container>
      <!-- 顶部导航栏 -->
      <!--      <div class="topbar"></div>-->
      <!-- 顶部导航栏END -->

      <!-- 顶栏容器 -->
      <el-header :class="{ 'home-header': true, 'home-header-fixed': show }">
        <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            active-text-color="#fff"
            text-color="#555555"
            router
            @select="handleSelect"
        >
          <div class="navbar-header">
            <div class="logo">
              <router-link to="/">
                <img :src="config.logo" v-if="config.logo" alt style="margin: 10px"/>

              </router-link>
            </div>
            <div class="navbar-buttons">
              <span class="navbar-toggle">
                <i class="el-icon-s-operation" @click="changeMenu"></i>
              </span>
            </div>
          </div>
          <transition name="slide"  >
            <div class="navbar-collapse" v-if="isCollapse">
              <div class="header-ul">
                <el-menu-item index="/" style="height: 100%"><span>PRODUCT</span> </el-menu-item>
                <!--                <el-menu-item index="home" style="height: 100%"><span>HOME</span></el-menu-item>-->
                <!--                <el-menu-item index="services" style="height: 100%"><span>SERVICES</span></el-menu-item>-->
                <!--                <el-menu-item index="FAQ" style="height: 100%"><span>FAQ</span></el-menu-item>-->
                <el-menu-item index="contact" style="height: 100%"><span>CONTACT</span></el-menu-item>
                <el-menu-item index="login" v-if="!islogin"><span style="text-decoration: none !important">Login</span></el-menu-item>
                <div  style="line-height: 60px" v-if="!islogin">
                  <el-dropdown>
                    <span class="el-dropdown-link" style="font-weight: bold;margin-left: 20px">
                      <img :src="chooseImg" v-if="chooseImg" style="width: 30px;margin: 5px"/>
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="changeCountry(americaImg,'price_america')">
                        <img :src="americaImg" v-if="americaImg" style="width: 30px;margin: 5px"/>US
                      </el-dropdown-item>
                      <el-dropdown-item @click.native="changeCountry(canadaImg,'price_canada')">
                        <img :src="canadaImg" v-if="canadaImg" style="width: 30px;margin: 5px"/>Canada
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div v-if="islogin" style="line-height: 60px">
                  <el-dropdown>
                    <span class="el-dropdown-link" style="font-weight: bold;margin-left: 20px">
                      {{ nickname }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="tofootmark()">footmark</el-dropdown-item>
                      <el-dropdown-item @click.native="tocollect()">Collect</el-dropdown-item>
                      <el-dropdown-item @click.native="tocatalogue()">Catalogue</el-dropdown-item>
                      <!--                                      <el-dropdown-item @click.native="tofeedback()">Contact</el-dropdown-item>-->
                      <el-dropdown-item @click.native="logout()">logout</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <el-dropdown>
                    <span class="el-dropdown-link" style="font-weight: bold;margin-left: 20px">
                      <img :src="chooseImg" v-if="chooseImg" style="width: 30px;margin: 5px"/>
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="changeCountry(americaImg,'price_america')">
                        <img :src="americaImg" v-if="americaImg" style="width: 30px;margin: 5px"/>US
                      </el-dropdown-item>
                      <el-dropdown-item @click.native="changeCountry(canadaImg,'price_canada')">
                        <img :src="canadaImg" v-if="canadaImg" style="width: 30px;margin: 5px"/>Canada
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </transition>
        </el-menu>
      </el-header>
      <!-- 顶栏容器END -->

      <!-- 登录模块 -->
      <MyLogin></MyLogin>
      <!-- 注册模块 -->
      <MyRegister :register="register" @fromChild="isRegister"></MyRegister>

      <!-- 主要区域容器 -->
      <el-main>
        <keep-alive>
          <router-view v-if="$route.meta.isKeepAlive=='isBack'"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.isKeepAlive || $route.meta.isKeepAlive===''"></router-view>
      </el-main>
      <!-- 主要区域容器END -->

      <!-- 底栏容器 -->
      <el-footer>
        <div class="footer">
          <div class="content">
            <div class="left">{{config.footer_left}}</div>
            <div class="right" v-html="config.footer_right"></div>
          </div>
        </div>
      </el-footer>
      <!-- 底栏容器END -->
    </el-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { getHomeData } from "@/api/home.js";

export default {
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.showSearch);
    this.token = localStorage.getItem("token");
    if (this.token && this.token != "") {
      this.islogin = true;
      this.nickname = localStorage.getItem("nickname");
    } else {
      this.islogin = false;
    }

    let price_type = localStorage.getItem("price_type");
    if (price_type == "price_canada") {
      this.chooseImg = this.canadaImg;
    } else {
      this.chooseImg = this.americaImg;
    }
    this.getHomeList();
  },
  beforeUpdate() {
    this.activeIndex = 0;
  },
  data() {
    return {
      nickname: "",
      islogin: false,
      token: "",
      show: false,
      activeIndex: "0", // 头部导航栏选中的标签
      search: "", // 搜索条件
      register: false, // 是否显示注册组件
      visible: false, // 是否退出登录
      isCollapse: false, // 是否展示下拉
      config:{},
      chooseImg:require("@/assets/imgs/america.png"),
      americaImg: require("@/assets/imgs/america.png"),
      canadaImg: require("@/assets/imgs/canada.png"),
    };
  },
  created() {
    // 获取浏览器localStorage，判断用户是否已经登录
    if (localStorage.getItem("token")) {
      // 如果已经登录，设置vuex登录状态
      this.setUser(localStorage.getItem("token"));
    }
    this.isCollapse = false
    if(window.screen.width>768) {
      this.isCollapse = true
    }else {
      this.isCollapse = false
    }
  },
  computed: {
    ...mapGetters(["getUser", "getNum"]),
  },
  watch: {
    // 获取vuex的登录状态
    getUser: function (val) {
      if (val === "") {
        // 用户没有登录
        this.setShoppingCart([]);
      } else {
        // 用户已经登录,获取该用户的购物车信息
        this.islogin = true;
        this.nickname = localStorage.getItem("nickname");
      }
    },
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),
    login() {
      // 点击登录按钮, 通过更改vuex的showLogin值显示登录组件
      this.setShowLogin(true);
    },
    handleSelect() {
      if(window.screen.width>768) {
        this.isCollapse = true
      }else {
        this.isCollapse = false
      }
    },
    // 退出登录
    logout() {
      this.visible = false;
      // 清空本地登录信息
      localStorage.setItem("token", "");
      // 清空vuex登录信息
      this.setUser("");
      this.islogin = false;
      if(window.screen.width>768) {
        this.isCollapse = true
      }else {
        this.isCollapse = false
      }
      this.notifySucceed("成功退出登录");
      this.$router.go(0)
    },
    getHomeList() {
      getHomeData().then((res) => {
        const {config } = res.data;
        this.config = config;
      });
    },
    // 接收注册子组件传过来的数据
    isRegister(val) {
      this.register = val;
    },
    // 点击搜索按钮
    searchClick() {
      if (this.search != "") {
        // 跳转到全部商品页面,并传递搜索条件
        this.$router.push({ path: "/goods", query: { search: this.search } });
        this.search = "";
      }
    },
    showSearch() {
      let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 40) {
        // 当页面滚动到高度300px处，动态绑定class 来设置头部固定定位
        this.show = true;
      } else {
        this.show = false;
      }
    },
    changeCountry(img,price_type) {
      this.chooseImg = img;
      localStorage.setItem("price_type",price_type);
    },
    changeMenu() {
      this.isCollapse = !this.isCollapse
    },
    toproduct() {
      this.$router.push({ path: "/" });
    },
    tofootmark() {
      this.$router.push({
        path: "footmark",
      });
      if(window.screen.width>768) {
        this.isCollapse = true
      }else {
        this.isCollapse = false
      }
    },
    tocollect() {
      this.$router.push({
        path: "collect",
      });
      if(window.screen.width>768) {
        this.isCollapse = true
      }else {
        this.isCollapse = false
      }
    },
    tocatalogue() {
      this.$router.push({
        path: "catalogue",
      });
      if(window.screen.width>768) {
        this.isCollapse = true
      }else {
        this.isCollapse = false
      }
    },
    tofeedback() {
      this.$router.push({
        path: "feedback",
      });
      if(window.screen.width>768) {
        this.isCollapse = true
      }else {
        this.isCollapse = false
      }
    },
  },
};
</script>
<style lang="scss">
@import "assets/css/index";
</style>
<style scoped>
/* 覆盖Element UI的默认样式 */
.el-menu-item.is-active {
  background-color: #38a7bb !important; /* 你想要的颜色 */
  text-decoration: none;
}
.el-menu.el-menu--horizontal {
  border: none !important;
}
.el-menu--horizontal > .el-menu-item.is-active span {
  border-bottom: none !important;
}
.el-menu-item span {
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: bold;
}
/* .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
} */
.el-submenu {
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: bold !important;
}
.el-submenu__icon-arrow {
  display: none !important;
}
.el-submenu__title * {
  vertical-align: unset !important;
}
.el-menu-demo {
  --hover-color: #f0f9ff !important; /* 自定义悬浮颜色变量 */
}

.el-menu-item:hover {
  background-color: rgb(
      56 167 187 / 60%
  ) !important; /* 使用变量设置悬浮背景色 */
}
.el-submenu .el-submenu__title:hover {
  background-color: rgb(
      56 167 187 / 60%
  ) !important; /* 使用变量设置悬浮背景色 */
}
::v-deep .el-submenu__title i {
  font-weight: bold !important;
  color: #333 !important;
}

</style>
